export const environment = {
  production: false,
  apiDomain: "https://api-stage.psfyinsights.com", //'https://api-dev.psfyrab.net',
  apiBaseRoute: '/',
  idpBaseUrl: 'psfy-insights-stage.auth.us-east-1.amazoncognito.com', //'https://stage.psfyidp.com',
  cognitoUserPoolId: 'us-east-1_bCS65KzjF',
  region: 'us-east-1',
  cognitoAppIdInsightsSpa: '1ij62m2ecjknavbtro0uid32pm',
  cognitoAppIdMcTrade: '40epa7hrfdul4dp9m0qmk640rj',
  cognitoAppIdMcPro: 'q9gka02mgp5u3o637j9ud9gb9',
  cognitoAppIdPsfyEvents: '15nq9t8vv4t6s63vggck3o0u11',
  psfyIdpClientId: 'insights_stage_public_client',
  psfyIdpClientSecret: 'f29ec8a3-44d0-ed85-7750-2662cf44e4d7',
  psfyIdpBaseUrl:'https://stage.psfyidp.com'
};